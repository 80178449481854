<template>
  <el-dialog
    :title="'【查看】地址信息'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" label-width="80px" size="small">
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="地址编号" prop="customerCode">
            <el-input v-model="dataForm.addressCode" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属客户" prop="customerName">
            <el-input v-model="dataForm.customerName" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="地址类型" prop="settleType">
            <el-input v-model="dataForm.addressType" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工厂名称" prop="simpleName">
            <el-input v-model="dataForm.simpleName" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="dataForm.address" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系人" prop="contactName">
            <el-input v-model="dataForm.contactName" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="联系电话" prop="contactPhone">
            <el-input v-model="dataForm.contactPhone" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" :rows="5" v-model="dataForm.remark" readonly="readonly" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        addressCode: null,
        customerId: null,
        simpleName: null,
        contactName: null,
        contactPhone: null,
        addressType: null,
        address: null,
        remark: null,
        status: 1
      }
    }
  },
  methods: {
    init (id) {
      this.dataForm.id = id
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/customerAddress/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data) {
            this.dataForm.addressCode = data.addressCode
            this.dataForm.customerName = data.customerName
            this.dataForm.simpleName = data.simpleName
            this.dataForm.address = data.address
            this.dataForm.contactName = data.contactName
            this.dataForm.contactPhone = data.contactPhone
            this.dataForm.addressType = data.addressType === 1 ? '提货' : data.addressType === 2 ? '卸货' : '通用'
            this.dataForm.remark = data.remark
          }
        })
      }
    }
  }
}
</script>
