<template>
  <div class="order-examine-popup">
    <el-dialog :title="todo == 'view' ? '查看注意事项' : '维护注意事项'"  :close-on-click-modal="false" :visible.sync="visible" width="45%" center>
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="工厂注意事项" prop="reason">
              <el-input v-model="dataForm.remark" type="textarea" rows="2" :disabled="todo == 'view' ? true : false" placeholder="工厂注意事项限200字" maxlength="200" show-word-limit ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm" v-if="todo != 'view'">确 认</el-button>
        <el-button type="primary" @click="visible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      todo: 'view',
      dataForm: {
        remark: null,
        id: null
      },
      dataRule: {
        remark: [
          { required: true, message: '工厂注意事项不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init (row, todo) {
      this.visible = true
      this.todo = todo
      this.dataForm.remark = row.takeRemark
      this.dataForm.id = row.addressId
    },
    confirm () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (!this.dataForm.id) {
            this.$message.error('没有工厂信息，请维护')
            return false
          }
          let data = {
            ids: this.dataForm.id,
            remark: this.dataForm.remark
          }
          this.$http({
            url: this.$http.adornUrl(`/customerAddress/updateRemark`),
            method: 'POST',
            data: this.$http.adornData(data)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('维护失败')
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
